.form__avatarContainer {
  display: flex;
  margin: 32px 0 0 0;
}

.form__avatarContainer.none {
  display: none;
}

.form__avatarContainer.mixed {
  display: flex;
}

@media screen and (max-width: 600px) {
  .form__avatarContainer {
    display: none;
  }

  .form__avatarContainer.mixed {
    display: none;
  }
}
