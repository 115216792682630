.form__avatar {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .form__avatar {
    width: 80px;
    height: 80px;
  }
}

.form__textBlock {
  display: flex;
  flex-direction: column;
  margin: 17px 0 17px 24px;
  color: var(--color-white);
  line-height: 1.6;
}

@media screen and (max-width: 600px) {
  .form__textBlock {
    margin: 17px 0 17px 10px;
  }
}

.form__avatarTitle {
  font: var(--font-ml);
  font-weight: 500;
  text-transform: uppercase;
}

.form__avatarSubtitle {
  margin: 8px 0 0 0;
  font: var(--font-xs);
  color: var(--color-l-grey);
}
